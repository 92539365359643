import React, { useEffect, useState } from 'react';
import { Dialog } from 'new-ui';
import { getText } from '../../../../../i18n';

import { MainAnalytic } from '../../../../bi/utils/analytics';

import { ANALYTIC_SERVICE_TYPE_MATCH, SERVICETYPE } from '../../../../bi/constants/serviceType';
import { SERVICE_SOURCE_TYPE } from '../../../../bi/constants/trips';
import COUNTRIES from '../../../../bi/constants/countries';

import { CancelDialogContent } from './CancelDialogContent';

import { IRefund, IRefundAir } from '../../../../bi/types/order';
import OrderService from '../../../../bi/services/order';
import { IAdditionalService, ITripItem } from '../../../../bi/types/trips';
import ChatService from '../../../../bi/services/chat';

import styles from './styles/index.module.css';

const LABELS = {
  ERROR: getText('components:tripDetailsDialog.cancelDialog.error'),
  ERROR_AIR: getText('components:tripDetailsDialog.cancelDialog.errorAir'),
  ERROR_AIR_SUCCESS: getText('components:tripDetailsDialog.cancelDialog.errorAirSuccess'),
  ERROR_HOTEL: getText('components:tripDetailsDialog.cancelDialog.errorHotel'),
  CHAT_ERROR: (abroad: string) => getText('services:chat.errorCancelOrder', { abroad }),
  ABROAD: getText('services:chat.abroad'),
};

interface CancelDialogProps {
  ticketsWithSamePNR?: any[],
  multipleTickets?: boolean,
  nameTrip: string,
  isTrain: boolean,
  isAir: boolean,
  isAeroexpress: boolean,
  isBus: boolean,
  isHotel: boolean,
  isTaxiVoucher: boolean,
  isTransfer: boolean,
  isVipHall: boolean,
  isChatLoading: boolean,
  isDisabled: boolean,
  orderService: OrderService,
  chatService: ChatService,
  item: ITripItem,
  onCloseDialog(): void,
  onRefreshTrain(): void,
  onSubmitCancellationModal(): void,
  onCancellationBack?(): void,
  onRequestAutomaticCancellation?(): void,
  onAutomaticCancellation?(): void,
  onCancelAutomaticCancellation?(): void,
  voidTimeLimit?: any,
  isCalculation: boolean,
  showElem: boolean,
  setShowElem: (value: boolean) => void,
  additionalServiceTrip?: IAdditionalService | null,
}

const CancelDialog = (props: CancelDialogProps) => {
  const {
    isTrain,
    isAir,
    isAeroexpress,
    isBus,
    isVipHall,
    isHotel,
    orderService,
    chatService,
    item,
    onCloseDialog,
    onRefreshTrain,
    onSubmitCancellationModal,
    onAutomaticCancellation = () => {},
    isTransfer,
    isCalculation,
  } = props;

  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [loadingCancellation, setLoadingCancellation] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [refundAir, setRefundAir] = useState<IRefundAir | null>(null);
  const [refundVipHall, setRefundVipHall] = useState<IRefund | null>(null);
  const [refundTransfer, setRefundTransfer] = useState<IRefund | null>(null);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [cancelResult, setCancelResult] = useState({
    Success: true,
    Error: '',
  });

  const getRefundAir = async (TripItemId: number) => {
    setLoadingRefund(true);

    const response = await orderService.getRefundAir(TripItemId);

    setRefundAir(response);
    setLoadingRefund(false);
  };

  const getRefundTransfer = async (TripItemId: number) => {
    setLoadingRefund(true);

    const response = await orderService.getTransferRefund(TripItemId);

    setRefundTransfer(response);
    setLoadingRefund(false);
  };

  const getRefundVipHall = async (TripItemId: number) => {
    setLoadingRefund(true);

    const response = await orderService.getRefundVipHall(TripItemId);

    setRefundVipHall(response);
    setLoadingRefund(false);
  };

  useEffect(() => {
    const { ActualVersion: { TripItemId } } = item;

    if (isTransfer) {
      getRefundTransfer(TripItemId);
    }

    if (isAir) {
      getRefundAir(TripItemId);
    }

    if (isVipHall) {
      getRefundVipHall(TripItemId);
    }
  }, []);

  const isVipHallAutoCancellation =
    isVipHall &&
    refundVipHall?.Success &&
    item.ActualVersion.ServiceSourceType !== SERVICE_SOURCE_TYPE.BACKOFFICE;

  const isTransferAutoCancellation =
    isTransfer &&
    refundTransfer?.Success &&
    cancelResult.Success &&
    item.ActualVersion.ServiceSourceType !== SERVICE_SOURCE_TYPE.BACKOFFICE;

  const handleClose = () => {
    setLoadingCancellation(false);

    onCloseDialog();
    onRefreshTrain();
    onAutomaticCancellation();
  };

  const toggleErrorDialog = () => {
    setShowErrorDialog(!showErrorDialog);

    onCloseDialog();
    onRefreshTrain();
  };

  const handleCancelTrip = () => {
    const {
      ActualVersion: { TripItemId, ServiceType },
    } = item;

    setLoadingCancellation(true);

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_ANNULATION_CONFIRM_PRESSED(TripItemId, ANALYTIC_SERVICE_TYPE_MATCH[ServiceType]),
    );

    let method: any = () => {};

    switch (ServiceType) {
      case SERVICETYPE.AEROEXPRESS: {
        method = orderService.cancelAeroexpress;
        break;
      }
      case SERVICETYPE.TRAIN: {
        method = orderService.cancelTrain;
        break;
      }
      case SERVICETYPE.HOTEL: {
        method = orderService.cancelHotel;
        break;
      }
      case SERVICETYPE.AIR: {
        method = orderService.cancelAir;
        break;
      }
      case SERVICETYPE.TAXI_VOUCHER: {
        method = orderService.cancelTaxiVoucher;
        break;
      }
      case SERVICETYPE.VIP_HALL: {
        method = orderService.cancelVipHall;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        method = orderService.cancelTransfer;
        break;
      }
    }

    return method(TripItemId)
      .then((res: any) => {
        if (isAeroexpress && res?.Error) {
          return res;
        }

        if (isAir && !res?.Success) {
          setShowErrorDialog(!showErrorDialog);

          return setErrorMsg(LABELS.ERROR_AIR_SUCCESS);
        }

        if (isVipHall && !res?.Success) {
          setShowErrorDialog(!showErrorDialog);

          const error = res.Error || '';

          return setErrorMsg(error);
        }

        if (isHotel && !res?.Success) {
          const { Success } = res;
          const data = JSON.parse(item.ActualVersion.JsonData);

          const { CountryCode } = data.Hotel;
          const abroad = CountryCode && CountryCode !== COUNTRIES.RU.SHORTNAME
            ? LABELS.ABROAD
            : '';

          chatService.requestCancelByOrder(item, '', LABELS.CHAT_ERROR(abroad));

          const cancelRes = {
            Success,
            Error: LABELS.ERROR_HOTEL,
          };

          return setCancelResult(cancelRes);
        }

        if (isTrain && res?.Result === false && res?.Error.length > 0) {
          const { Result, Error } = res;
          const cancelRes = {
            Success: Result,
            Error,
          };

          return setCancelResult(cancelRes);
        }

        if (isTransfer && !res?.Success) {
          const { Success, Error } = res;

          const cancelRes = {
            Success,
            Error: Error || '',
          };

          return setCancelResult(cancelRes);
        }

        return handleClose();
      })
      .catch((err: any) => {
        if (isAir && err?.status === 500) {
          onSubmitCancellationModal();

          return;
        }

        const errorText = isAir ? LABELS.ERROR_AIR : LABELS.ERROR;

        setShowErrorDialog(!showErrorDialog);
        setErrorMsg(errorText);
      });
  };

  return (
    <Dialog
      show
      showClosing={ !loadingCancellation }
      className={ styles.wrapper }
      outsideClickClosing={ !loadingCancellation }
      onChange={ onCloseDialog }
    >
      <CancelDialogContent
        { ...props }
        item={ item }
        isSuccessAirRefund={ !!refundAir?.Success }
        isSuccessTransferRefund={ !!refundTransfer?.Success }
        cancelError={ cancelResult.Error }
        showErrorDialog={ showErrorDialog }
        loadingCancellation={ loadingCancellation }
        errorMsg={ errorMsg }
        onCancelTrip={ handleCancelTrip }
        toggleErrorDialog={ toggleErrorDialog }
        isVipHallAutoCancellation={ !!isVipHallAutoCancellation }
        isTransferAutoCancellation={ !!isTransferAutoCancellation }
        isBus={ isBus }
        loadingRefund={ loadingRefund }
        isCalculation={ isCalculation }
        orderService={ orderService }
      />
    </Dialog>
  );
};

export { CancelDialog };
