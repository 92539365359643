import React, { Component, createRef, ReactElement } from 'react';
import { Moment } from 'moment';
import { Datepicker, IconButton, Text, TextType } from 'new-ui';
import { DatepickerThemes } from 'new-ui/src/components/Datepicker/types';

import { AirlineSearchMenuSuggestItem } from '../SuggestItem';

import splitWithoutRemovingSeparator from '../../../../../bi/utils/splitWithoutRemovingSeparator';

import { getText } from '../../../../../../i18n';

import DIRECTIONS from '../../../../../bi/constants/directions';
import { isSameDate } from '../../../../../bi/utils/formatDate';
import { isSmartAgent } from '../../../../../bi/utils/env';
import { MainAnalytic } from '../../../../../bi/utils/analytics';

import { SelectedRoutes } from '../../../../SelectedRoutes';

import { RouteField } from '../../../../../bi/services/airline/store/search';
import { IAirSearchRouteItem, IAirSearchRouteOpts } from '../../../../../bi/types/airline';
import { IAirlineSearchRouteProps, IAirlineSearchRouteState, IRenderSuggestionProps } from './types';

import stylesRow from './styles.module.css';

const LABELS = {
  DATE_TO: getText('components:menu.air.row.back'),
  CITY_FROM: getText('components:menu.air.row.cityFrom'),
  CITY_TO: getText('components:menu.air.row.cityTo'),
  DATE_FROM: getText('components:menu.air.row.to'),
};

const FIELDS = {
  FLIP: 'flip',
  DATE: 'date',
  DATE_BACK: 'date.back',
  SUGGEST_SELECT: 'suggest.select',
};

class AirlineSearchRoute extends Component<IAirlineSearchRouteProps, IAirlineSearchRouteState> {
  static defaultProps = {
    theme: 'light',
    isChangeAirTrip: false,
    numberOfRoutes: 0,
    qaAttrs: null,
  };

  suggestFrom = createRef<HTMLInputElement>();
  suggestTo = createRef<HTMLInputElement>();

  state: IAirlineSearchRouteState = {
    dateOpen: false,
    dateBackOpen: false,
  };

  handleFlipFromTo = (key: number) => this.props.airlineService.setSearchValue(FIELDS.FLIP, key);

  handleChangeBackDate = (key: number, value: string | Moment | null) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_DATE_CHOSEN, {
      date: value,
    });

    return this.props.airlineService.setSearchValue(FIELDS.DATE_BACK, { key, value });
  };

  handleSelectSuggest = (
    field: string,
    key: number,
    value: IAirSearchRouteItem,
  ) => this.props.airlineService.setSearchValue(FIELDS.SUGGEST_SELECT, { field, key, value });

  handleRemoveRoute = (routeIndex: number) => this.props.airlineService.removeSearchRoute(routeIndex);

  getSuggest = (
    field: string,
    key: number,
    query: string,
  ) => this.props.airlineService.autocomplete(field as RouteField, key, query);

  clearSuggests = (
    field: string,
    ind: number,
  ) => this.props.airlineService.clearSuggests(field as RouteField, ind);

  handleOpenDate = (value: boolean) => this.setState({ dateOpen: value });

  handleOpenDateBack = (value: boolean) => this.setState({ dateBackOpen: value });

  handleSuggestSelected = (field: string, suggest: IAirSearchRouteItem, ind: number) => {
    const { route } = this.props;

    if (suggest !== null) {
      const previousSelected = route[field as RouteField].label;
      const newSelected = `${suggest.City} (${suggest.Code})`;

      this.handleSelectSuggest(field, ind, suggest);

      if (field === DIRECTIONS.FROM && newSelected !== previousSelected) this.suggestTo.current?.focus();

      if (field === DIRECTIONS.TO && newSelected !== previousSelected) {
        this.handleOpenDate(true);
      }

      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_CITY_CHOSEN, {
        city: newSelected,
      });
    }
  };

  handleDatePickerBlur = () => {
    const { route } = this.props;

    if (!route || !route.from.selected || !route.to.selected) {
      this.suggestFrom.current?.focus();
    }
  };

  handleChangeFromDate = (ind: number, value: Moment | string | null) => {
    const { route: { date }, airlineService: { setSearchValue } } = this.props;

    setSearchValue(FIELDS.DATE, { key: ind, value });

    if (!isSameDate(value, date)) {
      this.handleOpenDateBack(true);
    }

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AIRLINE.SEARCH_AIR_DATE_CHOSEN, {
      date: value,
    });
  };

  renderSuggestion = ({ City, Name, Code }: IRenderSuggestionProps, query: string) => {
    if (!query) {
      return <AirlineSearchMenuSuggestItem city={ City } name={ Name } code={ Code } />;
    }

    const querySplit = splitWithoutRemovingSeparator(query);
    const city = this.getWordSuggest(City, querySplit);
    const name = this.getWordSuggest(Name, querySplit, 'SEMIBOLD_12');
    const code = this.getWordSuggest(Code, querySplit);

    return <AirlineSearchMenuSuggestItem city={ city } name={ name } code={ code } />;
  };

  getWordSuggest = (name: string, query: string[], typeText?: TextType) => splitWithoutRemovingSeparator(name)
    .reduce((acc, word) => {
      const foundQueryWord = query.find((queryWord) => word.toLowerCase().startsWith(queryWord.toLowerCase()));

      if (foundQueryWord) {
        return [...acc, ...this.highlightWordStart(word, foundQueryWord, typeText)];
      }

      return [...acc, word];
    }, [] as (ReactElement | string)[]);

  highlightWordStart = (string: string, query: string, typeText = 'SEMIBOLD_16' as TextType) => {
    const parts = splitWithoutRemovingSeparator(string, query, true);

    return [
      <Text
        type={ typeText }
        className={ stylesRow.highlight }
        key={ Math.random() * Math.random() }
      >
        { parts[0] }
      </Text>,
      ...parts.slice(1),
    ];
  };

  renderDateBack = () => {
    const {
      ind,
      route: {
        dateBack,
        date,
        minDate,
      },
      theme,
      qaAttrs,
    } = this.props;

    return (
      <div className={ stylesRow.date }>
        <Datepicker
          closeOnTabOut
          withLabel
          isCleansing
          theme={ theme as DatepickerThemes }
          open={ this.state.dateBackOpen }
          placeholder={ LABELS.DATE_TO }
          inputTheme='open'
          value={ dateBack }
          min={ date || minDate }
          onChange={ value => this.handleChangeBackDate(ind, value) }
          onBlur={ this.handleDatePickerBlur }
          wrapperClassName={ stylesRow.wrapper }
          inputClassName={ stylesRow.input }
          isDuration
          durationDates={ [date, dateBack as Moment] }
          alternativeDesign={ isSmartAgent }
          qaAttr={ qaAttrs?.dates?.to || '' }
        />
      </div>
    );
  };

  renderRemoveRote = () => {
    const { ind, qaAttrs } = this.props;

    const qaAttr = qaAttrs?.deleteRoute ? `${qaAttrs.deleteRoute}-${ind}` : '';

    const iconHtml = ind !== 0 && (
      <IconButton
        iconType='closeButton'
        onClick={ () => this.handleRemoveRoute(ind) }
        qaAttr={ qaAttr }
        alternativeDesign={ isSmartAgent }
      />
    );

    return (
      <div className={ stylesRow.clear }>
        {iconHtml}
      </div>
    );
  };

  renderRemoveRoteChangeAirTrip = () => {
    const { ind, numberOfRoutes, qaAttrs } = this.props;

    const qaAttr = qaAttrs?.deleteRoute ? `${qaAttrs.deleteRoute}-${ind}` : '';

    const iconHtml = (
      <IconButton
        iconType='closeButton'
        onClick={ () => this.handleRemoveRoute(ind) }
        qaAttr={ qaAttr }
      />
    );

    if (numberOfRoutes !== 1) {
      return (
        <div className={ stylesRow.clear_change }>
          {iconHtml}
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      ind,
      route,
      isComplex,
      theme,
      isChangeAirTrip,
      qaAttrs,
    } = this.props;

    const { from, to, date, dateBack, minDate } = route;

    const rowStyle = [stylesRow.row];
    const inputStyles = [stylesRow.input];

    const removeRoteHtml = () => {
      if (isComplex && !isChangeAirTrip) {
        return this.renderRemoveRote();
      }

      if (isComplex && isChangeAirTrip) {
        return this.renderRemoveRoteChangeAirTrip();
      }

      return null;
    };

    const dateBackHtml = !isComplex && this.renderDateBack();
    let qaAttrDateFrom = qaAttrs?.dates?.from || '';

    if (theme) {
      rowStyle.push(stylesRow[theme]);
    }

    if (ind !== 0) {
      rowStyle.push(stylesRow.border);
      qaAttrDateFrom = qaAttrs?.dates?.from ? `${qaAttrs.dates.from}-${ind}` : '';
    }

    if (isChangeAirTrip) {
      inputStyles.push(stylesRow.input_change_air_trip);
    }

    const opts: IAirSearchRouteOpts = {
      from: {
        value: from.label,
        items: from.suggestions,
        placeholder: LABELS.CITY_FROM,
        ref: this.suggestFrom,
        loading: from.loading as boolean,
        onSelect: (suggest: IAirSearchRouteItem) =>
          this.handleSuggestSelected(DIRECTIONS.FROM, suggest, ind),
        onChange: v => this.getSuggest(DIRECTIONS.FROM, ind, v),
        onClear: () => this.clearSuggests(DIRECTIONS.FROM, ind),
      },
      to: {
        value: to.label,
        items: to.suggestions,
        placeholder: LABELS.CITY_TO,
        ref: this.suggestTo,
        loading: to.loading as boolean,
        onSelect: (suggest: IAirSearchRouteItem) =>
          this.handleSuggestSelected(DIRECTIONS.TO, suggest, ind),
        onChange: v => this.getSuggest(DIRECTIONS.TO, ind, v),
        onClear: () => this.clearSuggests(DIRECTIONS.TO, ind),
      },
    };

    return (
      <div
        className={ rowStyle.join(' ') }
        // @ts-ignore
        ref={ (suggestForm) => { this.suggestForm = suggestForm; } }
      >
        <SelectedRoutes
          opts={ opts }
          theme={ theme }
          keyExtractor={ ({ Code }) => Code }
          nestedType='column'
          renderItem={ this.renderSuggestion as () => JSX.Element }
          onRevert={ () => this.handleFlipFromTo(ind) }
          ind={ ind }
          qaAttrs={ qaAttrs?.cities }
        />
        <div className={ stylesRow.date }>
          <Datepicker
            closeOnTabOut
            withLabel
            theme={ theme as DatepickerThemes }
            open={ this.state.dateOpen }
            placeholder={ LABELS.DATE_FROM }
            inputTheme='open'
            value={ date }
            min={ minDate }
            onChange={ value => this.handleChangeFromDate(ind, value) }
            wrapperClassName={ stylesRow.wrapper }
            inputClassName={ inputStyles.join(' ') }
            isDuration
            durationDates={ [date, dateBack as Moment] }
            qaAttr={ qaAttrDateFrom }
            alternativeDesign={ isSmartAgent }
          />
        </div>
        { dateBackHtml }
        { removeRoteHtml() }
      </div>
    );
  }
}

export { AirlineSearchRoute };
