import React from 'react';

import { Text } from 'new-ui';
import { getText } from '../../../i18n';

import { UnderageWarning } from '../UnderageWarning';
import { InfoProviderIM } from '../InfoProviderIM';

import { dateWithoutMoment, formatDate } from '../../bi/utils/formatDate';
import { preparePlaceNumber, getSeatType } from '../../bi/utils/train';
import getAccountId from '../../bi/utils/getAccountId';

import { DATEFORMATS, PATTERN } from '../../bi/constants/dateFormats';
import { CANCELLATION_TRAIN, TRAIN_TARIFFS } from '../../bi/constants/train';
import { SERVICETYPE } from '../../bi/constants/serviceType';
import { ACCOUNT_IM } from '../../bi/constants/accounts';

import { ItemType } from '../../page/TrainsResults/components/types';

import styles from './styles/index.module.css';

interface ITrainItem {
  item: ItemType;
  isCancellation?: boolean;
  hasUnderageEmployees?: boolean;
  qaAttrFrom?: string;
  qaAttrTo?: string;
  qaAttrDateFrom?: string;
  qaAttrDateTo?: string;
  qaAttrCancellation?: string;
  qaAttrNumber?: string;
  qaAttrPlace?: string;
  differentTrains?: boolean;
  isSpecialTariffs?: boolean;
}

const LABELS = {
  TRAIN_NUMBER: getText('components:trainItem.trainNumber'),
  CAR_NUMBER: getText('components:trainItem.carNumber'),
  PLACE: getText('components:trainItem.place'),
  REFUND: getText('components:trainItem.refund'),
  ALL_COMPARTMENT: (typeShow: string) => getText('components:trainItem.allCompartment', { typeShow }),
  ATTENTION_KALININGRAD: {
    FIRST: getText('components:trainItem.attentionKaliningrad.first'),
    SECOND: getText('components:trainItem.attentionKaliningrad.second'),
    THIRD: getText('components:trainItem.attentionKaliningrad.third'),
  },
  KALININGRAD: getText('components:trainItem.kaliningrad'),
  SUITE: getText('constants:train.carType.suite'),
  SV: getText('constants:train.carType.vip'),

  SINGLE: getText('components:trainItem.specialTariffs.single'),
  BP: getText('components:trainItem.specialTariffs.bp'),
  KUPEK: getText('components:trainItem.specialTariffs.kupek'),
};

const TrainItem: React.FC<ITrainItem> = ({
  item: {
    ClassService,
    PlaceNumber,
    CarTypeDescription,
    DateDepartureLocal,
    DateDeparture,
    DateArrivalLocal,
    DateArrive,
    TrainNumber,
    TrainNumberLocal,
    CarNumber,
    StationDepart,
    StationArrive,
    StationFrom,
    StationTo,
    CancellationDate,
    Train,
    NonRefundable,
    PlacesInfo,
    ProviderName,
    SpecialTariff,
    TicketId,
  },
  isCancellation = false,
  // hasUnderageEmployees = false,
  qaAttrFrom = '',
  qaAttrTo = '',
  qaAttrDateFrom = '',
  qaAttrDateTo = '',
  qaAttrCancellation = '',
  qaAttrNumber = '',
  qaAttrPlace = '',
  differentTrains = false,
  isSpecialTariffs = false,
}) => {
  const getPlaneNum = (places: string | string[]) => {
    const buyFullWithoutScheme = Train ? !Train.Car.trainType && Train.Car.BuyTwo : false;

    if (typeof places === 'string') return places.split(';').join(', ');

    if (!Array.isArray(places)) return null;

    const firstPlace = preparePlaceNumber(places[0]);
    const lastPlace = preparePlaceNumber(places[places.length - 1]);

    if (firstPlace === lastPlace) return firstPlace;

    const delimiter = !buyFullWithoutScheme ? ' - ' : ', ';
    const postfix = !buyFullWithoutScheme ? '' : ` (${LABELS.ALL_COMPARTMENT(Train ? Train.Car.TypeShow : '')})`;

    return `${firstPlace}${delimiter}${lastPlace}${postfix}`;
  };

  const specialTariffsText = () => {
    if (!SpecialTariff) {
      return null;
    }

    if (differentTrains && SpecialTariff === TRAIN_TARIFFS.SINGLE) return LABELS.BP;

    if (SpecialTariff === TRAIN_TARIFFS.SINGLE) return LABELS.SINGLE;

    if (SpecialTariff === TRAIN_TARIFFS.KUPEK) return LABELS.KUPEK;

    return null;
  };

  const stationEqualCity = (station: string, city: string) => station.toLowerCase() === city.toLowerCase();

  const nonRefundable = Train ? Train.Car.NonRefundable : NonRefundable;

  const cancellationText = CancellationDate
    ? `${CANCELLATION_TRAIN.DATE} ${formatDate(CancellationDate, PATTERN.FULLDATEWITHTIME)}`
    : CANCELLATION_TRAIN.HOUR;
  const classService = ClassService ? `(${ClassService})` : '';
  const placeNumber = getPlaneNum(PlaceNumber);
  const seatType = getSeatType(PlaceNumber, Train, PlacesInfo);

  const departureDate = DateDepartureLocal || DateDeparture;
  const arrivalDate = DateArrivalLocal || DateArrive;

  const trainNumber = TrainNumberLocal || TrainNumber;

  const typeTrainEmployees = () => {
    const {
      Car: {
        Type,
        BuyTwo,
        JuniorTariff,
        SeniorTariff,
      },
    } = Train;

    const typeTrainSuiteOrSV = Type === LABELS.SUITE || Type === LABELS.SV;

    if (typeTrainSuiteOrSV && !!BuyTwo && !!JuniorTariff && !!SeniorTariff) {
      return SERVICETYPE.TRAIN_LUXE_NOT_CHILDREN_AND_OTHER;
    }

    if (typeTrainSuiteOrSV && !!BuyTwo && !JuniorTariff && !SeniorTariff) {
      return SERVICETYPE.TRAIN_LUXE_NOT_CHILDREN_NOT_OTHER;
    }

    if (!JuniorTariff && !!SeniorTariff) {
      return SERVICETYPE.TRAIN_NOT_JUNIOR;
    }

    if (!!JuniorTariff && !SeniorTariff) {
      return SERVICETYPE.TRAIN_NOT_SENIOR;
    }

    if (!JuniorTariff && !SeniorTariff) {
      return null;
    }

    return SERVICETYPE.TRAIN;
  };

  const underageEmployeesHtml = !!Train && !!typeTrainEmployees() && <UnderageWarning type={ typeTrainEmployees() }/>;

  const stationIncludeKaliningrad = stationEqualCity(StationDepart, LABELS.KALININGRAD) || stationEqualCity(StationArrive, LABELS.KALININGRAD);

  const accountId = getAccountId();

  const cancellationHtml = isCancellation && !nonRefundable && (accountId !== ACCOUNT_IM || TicketId) && (
    <Text
      className={ styles['cancellation-text'] }
      type='NORMAL_14'
      qaAttr={ qaAttrCancellation }
    >
      { cancellationText }
    </Text>
  );

  const renderSpecialTariffsHtml = () => {
    if (SpecialTariff && isSpecialTariffs) {
      return (
        <Text
          className={ styles['cancellation-text'] }
          type='NORMAL_14'
          qaAttr={ qaAttrCancellation }
        >
          { specialTariffsText() }
        </Text>
      );
    }

    return null;
  };

  const kaliningradAttentionHtml = stationIncludeKaliningrad && (
    <div>
      <Text className={ styles['secondary-text'] } type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.FIRST }
      </Text>
      <Text className={ styles['secondary-text'] } type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.SECOND }
      </Text>
      <Text className={ styles['secondary-text'] } type='NORMAL_14'>
        { LABELS.ATTENTION_KALININGRAD.THIRD }
      </Text>
    </div>
  );

  const nonRefundableHtml = nonRefundable && (
    <Text
      className={ styles['secondary-text'] }
      color='red'
      type='NORMAL_14'
    >
      {LABELS.REFUND}
    </Text>
  );

  const renderStationMainHtml = (station: string, qaAttr: string) => (
    <Text
      className={ `${styles.main_station} ${styles.capitalize}` }
      type='bold_18'
      qaAttr={ qaAttr }
    >
      { station.toLowerCase() }
    </Text>
  );

  const renderStationHtml = (station: string) => (
    <Text
      className={ `${styles.name} ${styles.capitalize}` }
      color='gray'
      type='NORMAL_14'
    >
      { station.toLowerCase() }
    </Text>
  );

  const renderDateHtml = (date: string, qaAttr: string) => (
    <div data-qa={ qaAttr } className={ styles.dates }>
      <Text
        className={ styles.time }
        type='bold_22'
      >
        { dateWithoutMoment(date, DATEFORMATS.TIME) }
      </Text>
      <Text
        type='NORMAL_14'
        color='gray'
      >
        { dateWithoutMoment(date, PATTERN.DATEWITHWEEKDAY) }
      </Text>
    </div>
  );

  return (
    <div className={ styles.item }>
      <div className={ styles['item-train'] }>
        <div className={ `${styles.station} ${styles.col_departure}` }>
          { renderStationMainHtml(StationDepart, qaAttrFrom) }
          { StationFrom && renderStationHtml(StationFrom) }
          { renderDateHtml(departureDate, qaAttrDateFrom) }
        </div>
        <div className={ styles['description-wrap'] }>
          <div className={ styles.description } data-qa={ qaAttrNumber }>
            <Text
              type='NORMAL_14'
              color='gray'
            >
              { LABELS.TRAIN_NUMBER }
            </Text>
            <Text
              className={ styles.content }
              type='NORMAL_14'
            >
              { trainNumber }
            </Text>
          </div>
          <div className={ styles.description }>
            <Text
              type='NORMAL_14'
              color='gray'
            >
              { LABELS.CAR_NUMBER }
            </Text>
            <Text
              className={ styles.content }
              type='NORMAL_14'
            >
              { CarNumber } { CarTypeDescription }
            </Text>
            <Text
              className={ styles.content }
              type='NORMAL_14'
              color='gray'
            >
              { classService }
            </Text>
          </div>
          { !!placeNumber && (
            <div className={ styles.description } data-qa={ qaAttrPlace }>
              <Text
                type='NORMAL_14'
                color='gray'
              >
                { LABELS.PLACE }
              </Text>
              <Text
                className={ styles.content }
                type='NORMAL_14'
              >
                { placeNumber }
              </Text>
              <Text
                className={ styles.content }
                type='NORMAL_14'
                color='gray'
              >
                { seatType }
              </Text>
            </div>
          ) }
        </div>
        <div className={ `${styles.station} ${styles.col_arrival}` }>
          { renderStationMainHtml(StationArrive, qaAttrTo) }
          { StationTo && renderStationHtml(StationTo) }
          { renderDateHtml(arrivalDate, qaAttrDateTo) }
        </div>
      </div>
      { cancellationHtml }
      { renderSpecialTariffsHtml() }
      { kaliningradAttentionHtml }
      { nonRefundableHtml }
      { underageEmployeesHtml }
      <InfoProviderIM providerName={ ProviderName } className={ styles.provider }/>
    </div>
  );
};

export default TrainItem;
