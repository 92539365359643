import React from 'react';
import { DotLoading, Text } from 'new-ui';

import { getText } from '../../../../../i18n';

import { HotelCancellation } from '../../../HotelCancellation';

import FormatAirRules from '../formatAirRules';
import { dateUtcFormat, formatDate } from '../../../../bi/utils/formatDate';

import ROUTES from '../../../../bi/constants/routes';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { PATTERN } from '../../../../bi/constants/dateFormats';

import { ICartItemInfo } from '../../../../bi/types/airlineAdditionalServices';

import styles from './styles/index.module.css';

const LABELS = {
  ERROR_LOADING: getText('components:rulesDialog.item.errorLoading'),
  NO_INFO: getText('components:rulesDialog.item.noInfo'),
  CONDITIONS: getText('components:rulesDialog.item.conditions'),
  AIR: {
    TYPE: getText('components:rulesDialog.item.services.air.type'),
  },
  HOTEL: {
    TYPE: getText('components:rulesDialog.item.services.hotel.type'),
  },
  TRANSFER: {
    TYPE: getText('components:rulesDialog.item.services.transfer.type'),
    INFO: getText('components:rulesDialog.item.services.transfer.info'),
  },
  TRAIN: {
    TYPE: getText('components:rulesDialog.item.services.train.type'),
    INFO: {
      1: getText('components:rulesDialog.item.services.train.info.1'),
      2: getText('components:rulesDialog.item.services.train.info.2'),
      3: getText('components:rulesDialog.item.services.train.info.3'),
      4: getText('components:rulesDialog.item.services.train.info.4'),
      5: getText('components:rulesDialog.item.services.train.info.5'),
      6: getText('components:rulesDialog.item.services.train.info.6'),
      7: getText('components:rulesDialog.item.services.train.info.7'),
      8: getText('components:rulesDialog.item.services.train.info.8'),
      9: getText('components:rulesDialog.item.services.train.info.9'),
      10: getText('components:rulesDialog.item.services.train.info.10'),
      11: getText('components:rulesDialog.item.services.train.info.11'),
      12: getText('components:rulesDialog.item.services.train.info.12'),
      13: getText('components:rulesDialog.item.services.train.info.13'),
      14: getText('components:rulesDialog.item.services.train.info.14'),
      15: getText('components:rulesDialog.item.services.train.info.15'),
      16: getText('components:rulesDialog.item.services.train.info.16'),
      17: getText('components:rulesDialog.item.services.train.info.17'),
      18: getText('components:rulesDialog.item.services.train.info.18'),
      19: getText('components:rulesDialog.item.services.train.info.19'),
      20: getText('components:rulesDialog.item.services.train.info.20'),
      21: getText('components:rulesDialog.item.services.train.info.21'),
      22: getText('components:rulesDialog.item.services.train.info.22'),
      23: getText('components:rulesDialog.item.services.train.info.23'),
      24: getText('components:rulesDialog.item.services.train.info.24'),
      25: getText('components:rulesDialog.item.services.train.info.25'),
      26: getText('components:rulesDialog.item.services.train.info.26'),
      27: getText('components:rulesDialog.item.services.train.info.27'),
      28: getText('components:rulesDialog.item.services.train.info.28'),
      29: getText('components:rulesDialog.item.services.train.info.29'),
      30: getText('components:rulesDialog.item.services.train.info.30'),
      31: getText('components:rulesDialog.item.services.train.info.31'),
      32: getText('components:rulesDialog.item.services.train.info.32'),
      33: getText('components:rulesDialog.item.services.train.info.33'),
      34: getText('components:rulesDialog.item.services.train.info.34'),
      35: getText('components:rulesDialog.item.services.train.info.35'),
      36: getText('components:rulesDialog.item.services.train.info.36'),
      37: getText('components:rulesDialog.item.services.train.info.37'),
      38: getText('components:rulesDialog.item.services.train.info.38'),
      39: getText('components:rulesDialog.item.services.train.info.39'),
      40: getText('components:rulesDialog.item.services.train.info.40'),
      41: getText('components:rulesDialog.item.services.train.info.41'),
      43: getText('components:rulesDialog.item.services.train.info.43'),
      44: getText('components:rulesDialog.item.services.train.info.44'),
      45: getText('components:rulesDialog.item.services.train.info.45'),
      46: getText('components:rulesDialog.item.services.train.info.46'),
      47: getText('components:rulesDialog.item.services.train.info.47'),
      48: getText('components:rulesDialog.item.services.train.info.48'),
      49: getText('components:rulesDialog.item.services.train.info.49'),
      50: getText('components:rulesDialog.item.services.train.info.50'),
      51: getText('components:rulesDialog.item.services.train.info.51'),
      52: getText('components:rulesDialog.item.services.train.info.52'),
      53: getText('components:rulesDialog.item.services.train.info.53'),
      54: getText('components:rulesDialog.item.services.train.info.54'),
      55: getText('components:rulesDialog.item.services.train.info.55'),
      56: getText('components:rulesDialog.item.services.train.info.56'),
      57: getText('components:rulesDialog.item.services.train.info.57'),
      58: getText('components:rulesDialog.item.services.train.info.58'),
      59: getText('components:rulesDialog.item.services.train.info.59'),
      60: getText('components:rulesDialog.item.services.train.info.60'),
    },
    RZD_RULES: getText('components:rulesDialog.item.services.train.rzdRules'),
  },
  FREE_BEFORE: (cancellationDate: string) => getText('components:hotelCancellation.freeBefore', { cancellationDate }),
};

interface RulesItemProp {
  item: ICartItemInfo;
  onGetRule: (id: number) => Promise<void>;
}

class RulesItem extends React.Component<RulesItemProp> {
  state = {
    loading: true,
  };

  componentDidMount = () => {
    const { item, onGetRule } = this.props;

    onGetRule(item.Id).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  renderLoading = () => (
    <div className={ styles.loading }>
      <DotLoading />
    </div>
  );

  render() {
    const { item } = this.props;
    const { CancellationInfo, ServiceType } = item;
    const dataCancellationHotel = JSON.parse(item.Data);
    const {
      room: {
        Amenities: {
          CancellationPenalties,
          CancellationInfo: cancellationInfo,
          HasCancellation,
        },
      } = { Amenities: { } },
    } = dataCancellationHotel;
    const cancellationDate = dateUtcFormat(cancellationInfo, PATTERN.DAY_OF_MONTH_TIME);

    if (ServiceType === SERVICETYPE.AEROEXPRESS) {
      return null;
    }

    const labelCancellation = LABELS.FREE_BEFORE(cancellationDate);

    const cancellationHotel = () => (
      <HotelCancellation
        cancellationPenalties={ CancellationPenalties }
        cancellationDate={ cancellationDate }
        cancellationInfo={ cancellationInfo }
        hasCancellation={ HasCancellation }
        cancellationText={ labelCancellation }
      />
    );

    let info: string | React.JSX.Element = '';
    const itemStyles = [styles.item];

    if (CancellationInfo) {
      switch (ServiceType) {
        case SERVICETYPE.AIR:
          info = FormatAirRules(CancellationInfo);
          break;
        case SERVICETYPE.HOTEL:
          info = cancellationHotel();
          itemStyles.push(styles.overflow);

          break;
        default:
          info = CancellationInfo;
      }
    } else {
      info = LABELS.ERROR_LOADING;
    }

    const data = JSON.parse(item.Data);

    let header = null;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        let name = `${data.Routes[0].Segments[0].DepartureCity} - ${data.Routes[0].Segments[data.Routes[0].Segments.length - 1].ArrivalCity}`;

        if (data.Routes.length === 2) {
          name += ` - ${data.Routes[1].Segments[data.Routes[1].Segments.length - 1].ArrivalCity}`;
        }

        header = (
          <div className={ styles.header }>
            <span>{LABELS.AIR.TYPE} { name }</span>
            <span>{ formatDate(item.CheckinDate) }</span>
          </div>);
        break;
      }
      case SERVICETYPE.HOTEL: {
        header = (
          <div className={ styles.header }>
            <span>{LABELS.HOTEL.TYPE} {data.hotel.Name}</span>
            <span>{ formatDate(item.CheckinDate) }</span>
          </div>
        );
        break;
      }
      case SERVICETYPE.TRANSFER: {
        item.CancellationInfo = LABELS.TRANSFER.INFO;
        header = (
          <div className={ styles.header }>
            <span>{LABELS.TRANSFER.TYPE}, { data.City } </span>
            <span>{ formatDate(item.CheckinDate) }</span>
          </div>
        );
        break;
      }
      case SERVICETYPE.TRAIN: {
        info = `
            <p>${LABELS.TRAIN.INFO['1']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['2']}</p>
            <br/>
            <h4>${LABELS.TRAIN.INFO['3']}</h4>
            <br/>
            <p>${LABELS.TRAIN.INFO['4']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['5']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['6']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['7']}</p>
            <br/>
            <h4>${LABELS.TRAIN.INFO['8']}</h4>
            <ul>
               <li>${LABELS.TRAIN.INFO['9']}</li>
               <li>${LABELS.TRAIN.INFO['10']}</li>
               <li>${LABELS.TRAIN.INFO['11']}</li>
            </ul>
            <br/>
            <p>${LABELS.TRAIN.INFO['58']}</p>
            <ul>
               <li>
                <a href=${ROUTES.RETURN_SAPSAN_PDF} target='_blank' rel='noreferrer'>
                  ${LABELS.TRAIN.INFO['59']}
                </a>
               </li>
               <li>
                <a href=${ROUTES.RETURN_SAPSAN_DOC} target='_blank' rel='noreferrer'>
                  ${LABELS.TRAIN.INFO['60']}
                </a>
               </li>
            </ul>
            <br/>
            <p>${LABELS.TRAIN.INFO['12']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['52']}</p>
            <ul>
               <li>${LABELS.TRAIN.INFO['53']}</li>
               <li>${LABELS.TRAIN.INFO['54']}</li>
            </ul>
            <br/>
            <p>${LABELS.TRAIN.INFO['55']}</p>
            <br/>
            <h4>${LABELS.TRAIN.INFO['13']}</h4>
            <br/>
            <p>${LABELS.TRAIN.INFO['14']}</p>
            <br/>
            <h4>${LABELS.TRAIN.INFO['15']}</h4>
            <br/>
            <h4>${LABELS.TRAIN.INFO['16']}</h4>
            <ul>
              <li>${LABELS.TRAIN.INFO['17']}</li>
              <li>${LABELS.TRAIN.INFO['18']}</li>
            </ul>
            <br/>
            <h4>${LABELS.TRAIN.INFO['19']}</h4>
            <ul>
              <li>${LABELS.TRAIN.INFO['56']}</li>
              <li>${LABELS.TRAIN.INFO['20']}</li>
              <li>${LABELS.TRAIN.INFO['21']}</li>
              <li>${LABELS.TRAIN.INFO['57']}</li>
            </ul>
            <br>
            <h4>${LABELS.TRAIN.INFO['22']}</h4>
            <br/>
            <h4>${LABELS.TRAIN.INFO['23']}</h4>
            <br>
            <h4>${LABELS.TRAIN.INFO['24']}</h4>
            <ul>
              <li>${LABELS.TRAIN.INFO['25']}</li>
              <li>${LABELS.TRAIN.INFO['26']}</li>
              <li>${LABELS.TRAIN.INFO['27']}</li>
              <li>${LABELS.TRAIN.INFO['28']}</li>
              <li>${LABELS.TRAIN.INFO['29']}</li>
            </ul>
            <br>
            <h4>${LABELS.TRAIN.INFO['30']}</h4>
            <ul>
              <li>${LABELS.TRAIN.INFO['31']}</li>
              <li>${LABELS.TRAIN.INFO['32']}</li>
              <li>${LABELS.TRAIN.INFO['33']}</li>
            </ul>
            <p>${LABELS.TRAIN.INFO['34']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['35']}</p>
            <br/>
            <h4>${LABELS.TRAIN.INFO['36']}</h4>
            <br/>
            <h4>${LABELS.TRAIN.INFO['37']}</h4>
            <ul>
              <li>${LABELS.TRAIN.INFO['38']}</li>
              <li>${LABELS.TRAIN.INFO['39']}</li>
            </ul>
            <br/>
            <h4>${LABELS.TRAIN.INFO['40']}</h4>
            <ul>
              <li>${LABELS.TRAIN.INFO['41']}</li>
            </ul>
            <p>${LABELS.TRAIN.INFO['43']}</p>
            <p>${LABELS.TRAIN.INFO['44']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['45']}</p>
            <br/>
            <p>${LABELS.TRAIN.INFO['46']}</p>
            <br/>
            <h4>${LABELS.TRAIN.INFO['47']}</h4>
            <br/>
            <h4>${LABELS.TRAIN.INFO['48']}</h4>
            <br/>
            <h4>${LABELS.TRAIN.INFO['49']}</h4>
            <ul>
              <li>${LABELS.TRAIN.INFO['50']}</li>
              <li>${LABELS.TRAIN.INFO['51']}</li>
            </ul>
            <br/>
            <a href=${ROUTES.RZD_RULES} target='_blank' rel='noreferrer'>
            ${LABELS.TRAIN.RZD_RULES}
            </a>`;
        header = (
          <div className={ styles.header }>
            <span>{LABELS.TRAIN.TYPE} { data.StationDepart } - { data.StationArrive } </span>
            <span>{ formatDate(item.CheckinDate) }</span>
          </div>
        );
        break;
      }
    }
    const rulesInfo = item.ServiceType === SERVICETYPE.HOTEL ? info : (
      <div
        className={ styles.info }
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={ { __html: (info as string || '').length > 0 ? info : LABELS.NO_INFO } }
      />
    );
    const html = this.state.loading ? this.renderLoading() : rulesInfo;

    return (
      <div className={ styles.wrapper }>
        <Text type='NORMAL_18'>{ header }</Text>
        <Text className={ styles.hint } type='NORMAL_16_140'>{LABELS.CONDITIONS}</Text>
        <div className={ itemStyles.join(' ') }>
          { html }
        </div>
      </div>
    );
  }
}

export default RulesItem;
