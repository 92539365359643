import { IParentCar } from '../services/trains/store/types';

interface Station {
  City: string,
  Code: string,
  CountryCode: null | string,
  Name: null | string,
}

interface TrainHistoryItem {
  DepartureDate: string,
  StationFrom: Station,
  StationTo: Station;
  Travellers: number,
}

interface TrainCars {
  Type: string,
  TypeIndex: number,
  FreePlaces: number,
  MinimalCost: {
    Base: number,
    Commission: number,
    Fee: number,
    Total: number,
    AgentFee: number,
  }
}

interface ITravelPolicy {
  Apply: boolean;
  Errors: {
    [key: string]: string;
  }
}

interface PriceDetails {
  Base: number;
  Fee: number;
  Commission: number;
  Total: number;
}

interface PlacesByCompartment {
  CompartmentNumber: string;
  Places: string[];
}

interface ICompartmentPlace {
  BuyFull: boolean;
  BuyFullAltPriceDetails: PriceDetails;
  BuyFullPriceDetails: PriceDetails;
  PlacesByCompartment: PlacesByCompartment;
  SpecialTariff: string;
  NonRefundable: boolean;
  TravellersCount: number;
}

interface IFreePlace {
  altPrice: number,
  buyFull: boolean,
  buyFullPrices: Record<number, number>,
  buyFullAltPrices: Record<number, number>,
  hasAlternative: boolean,
  nonRefundable: boolean,
  places?: string[],
  price: number,
  travellers: number,
  number: number,
  litter?: string,
  readOnly?: boolean,
  agentFee?: number,
  altFee?: number,
  qaAttr?: string,
}

interface ISelectedPlace {
  number: number;
  price?: number;
  nonRefundable?: boolean;
  hasAlternative?: boolean;
  altPrice?: number;
  altFee?: number;
  SpecialTariff?: string;
  CompartmentNumber?: string;
  agentFee?: number;
  compartmentAgentFee?: number;
}

interface ICarCss {
  width: number;
  height: number;
  top: number;
  left: number;
}

interface ICompartDetails {
  compartmentAltPrice?: number,
  compartmentPrice?: number;
  specialTariff?: string,
}

interface ICompartSection {
  CompartmentNumber?: string,
  SpecialTariff?: string,
  buyFullCompartment?: boolean,
  compartmentAltPrice?: number,
  compartmentPlaces?: string[],
  compartmentPrice?: number,
  hasAlternative?: boolean,
  compartmentAgentFee?: number,
  compartmentAltFee?: number,
  miniSections?: ICompartSection[],
}

interface IShemePlace {
  css: ICarCss,
  disabled: boolean,
  number: number,
  selected: boolean,
  type: string,
}

interface IShemeSection {
  css: ICarCss,
  number: number,
  places: IShemePlace[],
}

interface IAnimal {
  css: ICarCss,
}

interface ILevel {
  count: number,
  perSection: number,
  scheme: string,
  sections: IShemeSection[],
  info: any[],
  animal: IAnimal[],
}

interface IPlacesInfo {
  Number: string,
  SeatType: string,
}

interface ICarJsonData {
  type: string;
  levels: ILevel[],
}

interface ISection {
  css: ICarCss,
  litter: string,
  miniSections?: ICompartSection[],
  number: number,
  places: IPlace[],
  CompartmentNumber?: string,
  SpecialTariff?: string,
  buyFullCompartment?: boolean,
  compartmentAltPrice?: number,
  compartmentPlaces?: string[],
  compartmentPrice?: number,
  hasAlternative: boolean,
  compartmentAgentFee: number,
  compartmentAltFee: number,
}

interface IPlace {
  number: number,
  altPrice: number | undefined,
  altFee?: number | undefined,
  buyFullPrices?: Record<number, number>,
  buyFullPlaces?: number[] | null;
  buyFullAltPrices?: Record<number, number>,
  hasAlternative?: boolean,
  nonRefundable?: boolean,
  price?: number,
  travellers?: number,
  disabled: boolean,
  compartDetails: ICompartDetails | null;
  css?: ICarCss,
  type: string,
  selected: boolean,
  qaAttr?: string,
  readOnly?: boolean,
  alwaysDisabled?: boolean,
  agentFee: number | string,
}

interface IEntireCompartment {
  selectedPlace: ISelectedPlace[];
  entireCompartment: boolean;
  initialSelectedPlace: ISelectedPlace[];
  differentTrains: boolean,
  placesByCompartmentPrices: ICompartmentPlace[];
  setEntireCompartment: (vlaue: boolean) => void;
  travellers: number;
}

interface ITrain {
  ArrivalDate: string,
  ArrivalDateLocal: string,
  Cars: TrainCars[],
  ChangeDuration: number,
  DepartureDate: string,
  DepartureDateLocal: string,
  ERegistration: boolean,
  FavoriteId: string,
  FirmName: string;
  IsSuburban: boolean,
  NonRefundable: boolean,
  ProviderName: string,
  ProviderSource: string,
  Route: string[],
  StationCodeFrom: string,
  StationCodeTo: string,
  StationFrom: string,
  StationParentCodeFrom: string,
  StationParentCodeTo: string,
  StationTo: string,
  TrainId: number,
  TrainName: string,
  TrainNumber: string,
  TrainNumberLocal: string,
  TravelPolicy: ITravelPolicy,
  TravelTime: number,
  TransfersTime: number,
  CountTransfers: number,
  Price?: any;
  SearchId?: number;
  Trains: any;
  Car: IParentCar;
  Number: string,
}

type IArrSourcesWithTransfer = ISourcesWithTransfer[][];

interface ISourcesWithTransfer {
  Classes: any[],
  Id: number,
  Trains: ITrain[],
  TripDuration: number,
}

interface ISearchParam {
  Id: string | null,
  Trains: ITrain[],
  TrainsWithTransfer: IArrSourcesWithTransfer,
  TravelTime: number,
  TransfersTime?: number,
  CountTransfers: number,
}

interface ISortSelectItem {
  label: string,
  type: number | null,
  value: string,
  analyticValue?: AnalyticSortTrain,
}

interface CarDetails {
  MinimalCost: {
    Total: number,
    AgentFee: number
  };
  Type: string;
  FreePlaces: number;
}

interface TrainDetails {
  TrainId: number;
  DepartureDateLocal: string;
  DepartureDate: string;
  ArrivalDateLocal: string;
  ArrivalDate: string;
  StationFrom: string;
  StationTo: string;
  ChangeDuration: number;
  TravelTime: number;
  Cars: CarDetails[];
  TrainNumber: number;
  StationCodeFrom: string;
  StationCodeTo: string;
}

export enum AnalyticSortTrain {
  travel_time_ascending = 'travel_time_ascending',
  travel_time_descending = 'travel_time_descending',
  departure_time_descending = 'departure_time_descending',
  departure_time_ascending = 'departure_time_ascending',
  arrival_time_descending = 'arrival_time_descending',
  arrival_time_ascending = 'arrival_time_ascending',
  cheaper_first = 'cheaper_first',
  more_expensive_first = 'more_expensive_first',
}

export type {
  Station,
  TrainHistoryItem,
  ITrain,
  IEntireCompartment,
  ICompartmentPlace,
  IPlace,
  IFreePlace,
  ISelectedPlace,
  ISection,
  ICarJsonData,
  ICompartSection,
  ILevel,
  ICompartDetails,
  TrainCars,
  ISourcesWithTransfer,
  IArrSourcesWithTransfer,
  ISearchParam,
  ISortSelectItem,
  IShemeSection,
  IPlacesInfo,
  CarDetails,
  TrainDetails,
};
