import { observable, action, makeObservable } from 'mobx';

import { TEventValue } from '../type';
import { DEFAULT_EVENT } from '../const';

class Store {
  constructor() {
    makeObservable(this);
  }

  @observable events: TEventValue[] = [];
  @observable event: TEventValue = DEFAULT_EVENT;
  @observable filtered = {
    Statuses: [] as string[],
  };
  @observable loadingEvents: boolean = false;
  @observable errorEvents: boolean = false;
  @observable isOpenSwitcher: boolean = false;
  @observable valueSwitcher: string | null = null;
  @observable calculator = {
    value: 0,
    isLoading: false,
    isError: false,
  };

  @action
  setLoadingEvents = (value: boolean) => {
    this.loadingEvents = value;
  };

  @action
  setErrorEvents = (value: boolean) => {
    this.errorEvents = value;
  };

  @action
  getEvents = (value: TEventValue[]) => {
    this.events = value;
  };

  @action
  setCalculatorValue = (value: number) => {
    this.calculator.value = value;
  };

  @action
  setCalculatorLoading = (value: boolean) => {
    this.calculator.isLoading = value;
  };

  @action
  setCalculatorError = (value: boolean) => {
    this.calculator.isError = value;
  };

  getEvent = (value: TEventValue) => {
    this.event = value;
  };

  @action
  setStatusesFiltred = (value: string[]) => {
    this.filtered.Statuses = value;
  };

  @action
  createEvent = (value: TEventValue) => {
    this.events.push(value as TEventValue);
  };

  editName = (id: number, value: string) => {
    this.events[id].Name = value;
  };

  setOpenSwitcher = (value: boolean) => {
    this.isOpenSwitcher = value;
  };

  setValueSwitcher = (value: string | null) => {
    this.valueSwitcher = value;
  };
}

const EventStore = new Store();

export { EventStore };
