import React, { Component, ReactNode } from 'react';
import { Tooltip, Text, Button, Dropdown } from 'new-ui';

import { getText } from '../../i18n';
import { languages } from '../../i18n/locales/consts';

import { VERSIONSTATUS, TRIPSTATUS } from '../../App/bi/constants/trips';
import parseJsonString from '../../App/bi/utils/parseJsonString';
import { isSmartAgent } from '../../App/bi/utils/env';
import { MainAnalytic } from '../../App/bi/utils/analytics';
import { PROVIDER_IM } from '../../App/bi/constants/train';

import {
  SERVICETYPE,
  FLIGHT_CERTIFICATE_TYPES,
  ANALYTIC_SERVICE_TYPE_MATCH,
} from '../../App/bi/constants/serviceType';

import { ITripItemActualVersion } from '../../App/bi/types/trips';

import styles from './styles/tripDocuments.module.css';

const LABELS = {
  SEND_TO_EMAIL: getText('trip:documents.sendToEmail'),
  DOWNLOAD: getText('trip:documents.download'),
  TICKET_REFERENCE: getText('trip:documents.ticketRef'),
  E_TICKET_REFERENCE: getText('trip:documents.eTicketRef'),
  TICKET_REFERENCE_CAN_BE_DOWNLOAD: getText('trip:documents.downloadAfterEndTrip'),
  TICKET: getText('trip:documents.ticket'),
  E_TICKET: getText('trip:documents.eTicket'),
  SERVICE_FORM: getText('trip:documents.blankService'),
  APPROVE_BOOKING: getText('trip:documents.bookingApprove'),
  ADDITIONAL_SERVICE_PNR: (EmdNumber = null) => getText('trip:documents.additionalServicePnr', { EmdNumber }),
  SEND_TO_EMAIL_NOT_AVAILABLE: getText('trip:documents.sendToEmailNotAvailable'),
  FORMAT_PDF_RU: getText('trip:documents.formatPdfRu'),
  FORMAT_PDF_EN: getText('trip:documents.formatPdfEn'),
};

interface TripDocumentsState {
  loadingFlightCertificate: boolean,
  loadingButton: boolean,
}

interface TripDocumentsProps {
  id: number,
  item: ITripItemActualVersion,
  isProhibitionVoucher: boolean,
  flightCertificateStatus?: string,
  additionalServiceId?: number,
  tripId?: number,
  onSendDocument?(serviceType: string, id: number, employeeId: number): void,
  onPrintDocument?(orderItemId: number, employeeId: number, type: string): Promise<void>,
  onDownloadVoucherLocale?(tripItemId: number, locale: string): Promise<void>,
  onDownloadVoucher?(labelType: string): void,
  downloadServiceForm?(tripItemId: number, serviceId: number): Promise<void>,
  sendServiceForm?(tripItemId: number, employeeId: number, serviceId: number): void,
}

type RenderFunction = (firstEmployeeId?: number) => ReactNode;

interface MapServiceTypesToRenderDocuments {
  [key: string]: RenderFunction | (() => ReactNode);
}

export default class TripDocuments extends Component<TripDocumentsProps, TripDocumentsState> {
  private mapServiceTypesToRenderDocuments: MapServiceTypesToRenderDocuments;

  static defaultProps = {
    onPrintDocument: null,
    downloadServiceForm: null,
    onDownloadVoucher: () => {},
    onSendDocument: () => {},
    sendServiceForm: () => {},
    onDownloadVoucherLocale: () => {},
    additionalServiceId: null,
    isBookItem: false,
    flightCertificateStatus: '',
    tripId: 0,
  };

  constructor(props: TripDocumentsProps) {
    super(props);

    const firstEmployeeId = props.item.Employees.length ? props.item.Employees[0].Id : null;

    this.mapServiceTypesToRenderDocuments = {
      [SERVICETYPE.AIR]: this.renderAirDocuments,
      [SERVICETYPE.HOTEL]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.TRANSFER]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.VIP_HALL]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.TRAIN]: () => this.renderDocumentContent(this.renderTrainTicketNumber(), firstEmployeeId),
      [SERVICETYPE.AEROEXPRESS]: () => this.renderDocumentContent(this.renderAeroexspressTicketNumber(), firstEmployeeId),
      [SERVICETYPE.FLIGHT_CERTIFICATE]: this.renderFlightCertificate,
      [SERVICETYPE.CUSTOM]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.AIR_ADDITIONAL_SERVICE]: () => this.renderDocumentContent(this.renderAdditionalServiceText(), firstEmployeeId),
      [SERVICETYPE.INSURANCE]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
      [SERVICETYPE.BUS]: () => this.renderDocumentContent(LABELS.APPROVE_BOOKING, firstEmployeeId),
    };

    this.state = {
      loadingFlightCertificate: false,
      loadingButton: false,
    };
  }

  isServiceForm = () => this.props.additionalServiceId !== null;

  toggleLoadingFlightCertificate = (value: boolean) => this.setState({
    loadingFlightCertificate: value,
  });

  toggleLoadingButton = (value: boolean) => this.setState({
    loadingButton: value,
  });

  handleDownload = (employeeId: number | null) => {
    const {
      id,
      item: { ServiceType },
      onPrintDocument,
      onDownloadVoucher,
      downloadServiceForm,
      additionalServiceId,
    } = this.props;

    if (this.isServiceForm() && downloadServiceForm) {
      downloadServiceForm(id, additionalServiceId as number).finally(this.stopLoading);

      return;
    }

    if (onPrintDocument) onPrintDocument(id, employeeId as number, ServiceType).finally(this.stopLoading);

    if (onDownloadVoucher) onDownloadVoucher(ServiceType);
  };

  handleDownloadVoucherLocale = (locale: string) => {
    const { id, onDownloadVoucherLocale, item: { ServiceType }, tripId } = this.props;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.TRIPS.TRIP_VOUCHER_PRESSED(tripId, ANALYTIC_SERVICE_TYPE_MATCH[ServiceType], locale),
    );

    return onDownloadVoucherLocale ? onDownloadVoucherLocale(id, locale).finally(this.stopLoading) : () => {};
  };

  handleDownloadCertificate = (employeeId: number) => {
    this.toggleLoadingFlightCertificate(true);
    this.handleDownload(employeeId);
  };

  handleDownloadButton = (employeeId: number | null) => {
    this.toggleLoadingButton(true);
    this.handleDownload(employeeId);
  };

  handleDownloadVoucherLocaleButton = (locale: string) => {
    this.toggleLoadingButton(true);

    this.handleDownloadVoucherLocale(locale);
  };

  stopLoading = () => {
    this.toggleLoadingFlightCertificate(false);
    this.toggleLoadingButton(false);
  };

  renderFlightCertificate = () => {
    const { loadingFlightCertificate } = this.state;
    const { item: { JsonData }, flightCertificateStatus } = this.props;

    const canDownload = flightCertificateStatus === FLIGHT_CERTIFICATE_TYPES.CAN_DOWNLOAD
      || flightCertificateStatus === FLIGHT_CERTIFICATE_TYPES.FAILED;

    const itemData = parseJsonString(JsonData);

    const ticket = itemData ? `${LABELS.TICKET_REFERENCE} #${itemData.FlightData.Tickets}` : LABELS.E_TICKET_REFERENCE;

    const downLoadBtnHtml = (
      <Button
        loading={ loadingFlightCertificate }
        type='textual'
        disabled={ !canDownload }
        onClick={ () => this.handleDownloadCertificate(itemData.AirTripItemId) }
      >
        { LABELS.DOWNLOAD }
      </Button>
    );

    const downloadBtnWithTooltipHtml =
      <Tooltip
        className={ styles.tooltip_wrap }
        renderContent={ () => (
          <Text type='NORMAL_14_130' color='white' className={ styles.tooltip_content }>
            { LABELS.TICKET_REFERENCE_CAN_BE_DOWNLOAD }
          </Text>
        ) }
      >
        { downLoadBtnHtml }
      </Tooltip>;

    const buttonHtml = canDownload ? downLoadBtnHtml : downloadBtnWithTooltipHtml;

    return (
      <div className={ styles.flight_certificate_wrap }>
        <Text color='gray'>{ ticket }: </Text>
        { buttonHtml }
      </div>
    );
  };

  renderAdditionalServiceText = () => {
    const { item: { ServiceType, JsonData } } = this.props;
    const { EmdNumber } = JSON.parse(JsonData);

    if (ServiceType !== SERVICETYPE.AIR_ADDITIONAL_SERVICE) return null;

    return LABELS.ADDITIONAL_SERVICE_PNR(EmdNumber);
  };

  renderTicketNumber = () => {
    const { item } = this.props;

    if (this.isServiceForm()) {
      return LABELS.SERVICE_FORM;
    }

    const itemData = parseJsonString(item.JsonData);

    if (itemData && itemData.TicketsExtended && itemData.TicketsExtended[0] && itemData.TicketsExtended[0].Num) {
      return `${LABELS.TICKET} #${itemData.TicketsExtended[0].Num}`;
    }

    return LABELS.E_TICKET;
  };

  renderAeroexspressTicketNumber = () => {
    const { item: { JsonData } } = this.props;

    const itemData = parseJsonString(JsonData);
    const { Ticket: { Id } } = itemData;

    return itemData && Id ? `${LABELS.TICKET} #${Id}` : LABELS.E_TICKET;
  };

  renderTrainTicketNumber = () => {
    const { item: { JsonData, Status } } = this.props;
    const itemData = parseJsonString(JsonData);

    const { TicketNumber } = itemData;

    if (Status === VERSIONSTATUS.CANCELLED) {
      return null;
    }

    return itemData && TicketNumber ? `${LABELS.TICKET} #${TicketNumber}` : LABELS.E_TICKET;
  };

  isDisabled = (status: any) =>
    (!!(status === TRIPSTATUS.AWAITINGCONFIRMATION ||
    status === TRIPSTATUS.AWAITINGTICKETING ||
    status === TRIPSTATUS.CREATED_EVENT));

  renderButtonDownload = (employeeId: number | null) => {
    const { status = null } = this.props;

    return (
      <>
        <Button
          disabled={ status ?
            this.isDisabled(status) :
            null }
          loading={ this.state.loadingButton }
          type='textual'
          className={ styles.btn }
          onClick={ () => this.handleDownloadButton(employeeId) }
        >
          { LABELS.DOWNLOAD }
        </Button>
      </>
    );
  };

  renderDropdownHtml = (employeeId: number | null) => (
    <div className={ styles.label }>
      <Dropdown
        isOpenMenu
        hoverOpenMenu
        renderLabel={ () => this.renderButtonDownload(employeeId) }
      >
        <div className={ styles.content }>
          <Button
            type='textual'
            onClick={ () => this.handleDownloadVoucherLocaleButton(languages.ru) }
          >
            <Text color='default' type='NORMAL_16'>{ LABELS.FORMAT_PDF_RU }</Text>
          </Button>
        </div>
        <div className={ styles.content }>
          <Button
            type='textual'
            onClick={ () => this.handleDownloadVoucherLocaleButton(languages.en) }
          >
            <Text color='default' type='NORMAL_16'>{ LABELS.FORMAT_PDF_EN }</Text>
          </Button>
        </div>
      </Dropdown>
    </div>
  );

  renderDownloadButton = (employeeId: number | null) => {
    const { onPrintDocument, onDownloadVoucher, item: { ServiceType } } = this.props;

    if (!onPrintDocument && !onDownloadVoucher) return null;

    const avalibleServiceType =
      ServiceType === SERVICETYPE.AIR ||
      ServiceType === SERVICETYPE.HOTEL ||
      ServiceType === SERVICETYPE.TRANSFER ||
      ServiceType === SERVICETYPE.VIP_HALL;

    return avalibleServiceType && isSmartAgent ? this.renderDropdownHtml(employeeId) : this.renderButtonDownload(employeeId);
  };

  renderTooltip = () => (
    <Text
      color='white'
      type='NORMAL_14_130'
      className={ styles.tooltip_box }
    >
      {LABELS.SEND_TO_EMAIL_NOT_AVAILABLE}
    </Text>
  );

  renderSendButton = (employeeId: number | null) => {
    const {
      id,
      onSendDocument,
      sendServiceForm,
      additionalServiceId,
      isProhibitionVoucher,
      item: {
        ServiceType,
        Status,
      },
      status = null,
    } = this.props;

    if (Status === VERSIONSTATUS.CANCELLED) {
      return null;
    }

    const isDisabled = this.isDisabled(status);
    const disabledButton = isDisabled || isProhibitionVoucher;

    const handleSendDocument = this.isServiceForm() ?
      // @ts-ignore
      () => sendServiceForm(id, employeeId as number, additionalServiceId as number) :
      () => onSendDocument(ServiceType, id, employeeId as number);

    return (
      <Tooltip
        show={ isProhibitionVoucher }
        renderContent={ this.renderTooltip }
      >
        <Button
          type='textual'
          className={ styles.btn }
          disabled={ disabledButton }
          onClick={ handleSendDocument }
        >
          { LABELS.SEND_TO_EMAIL }
        </Button>
      </Tooltip>
    );
  };

  renderDocumentContent = (title: string | null, employeeId: number | null) => {
    const { item: { JsonData, ServiceType } } = this.props;

    if (ServiceType === SERVICETYPE.CUSTOM && !JSON.parse(JsonData).VoucherFileName) return null;

    // Ah shit, here we go again
    const colon = ServiceType !== SERVICETYPE.AIR_ADDITIONAL_SERVICE ? ':' : null;

    return (
      <div className={ styles.document }>
        <Text color='gray'>{ title && `${title}` }{ colon } </Text>
        { this.renderSendButton(employeeId) }
        { this.renderDownloadButton(employeeId) }
      </div>
    );
  };

  renderAirDocuments = () =>
    this.props.item.Employees.map(({ Id }) => (
      <div key={ Id }>{ this.renderDocumentContent(this.renderTicketNumber(), Id) }</div>
    ));

  render() {
    const { item: { ServiceType, Status, ProviderName }, onSendDocument, onPrintDocument, onDownloadVoucher } = this.props;

    const isIm = ProviderName === PROVIDER_IM;
    const haveAnyFn = onSendDocument || onPrintDocument || onDownloadVoucher;
    const isValid = Status === VERSIONSTATUS.CANCELLED && !isIm;

    if (isValid || !haveAnyFn) return null;

    const block = !!this.mapServiceTypesToRenderDocuments[ServiceType]
      && this.mapServiceTypesToRenderDocuments[ServiceType]();

    return (
      <div className={ styles.wrapper }>
        { block }
      </div>
    );
  }
}
